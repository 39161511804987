<template>
  <Wrapper>
    <div class="mb-3 text-left">
      <h2 class="text-xl"><b>Información del nuevo usuario</b></h2>
      <p class="text-gray-500">
        Ingrese la información del usuario y el tipo de rol administrativo
      </p>
    </div>
    <form @submit.prevent="createUser" class="mx-auto max-w-2xl" ref="form">
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseInput
          ref="names"
          type="text"
          label="Nombres"
          placeholder="Ej: Carlos Andrés"
          v-model="form.first_name"
          :maxlength="25"
          required="true"
        />
        <BaseInput
          ref="lastname"
          type="text"
          label="Apellidos"
          placeholder="Ej: Jaramillo Pastrana"
          v-model="form.last_name"
          :maxlength="24"
          required="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseSelect
          ref="documentType"
          label="Tipo de documento"
          :selectOptions="formOptions.optionsIdentificationType"
          v-model="form.identification_type"
          required="true"
        />
        <BaseInput
          ref="documentNumber"
          type="text"
          :identification="form.identification_type"
          label="No. de documento"
          placeholder="Ej: 4523453452"
          v-model="form.identification_number"
          required="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseSelect
          ref="phone_country_abrev"
          id="phone_country_abrev"
          label="País del teléfono"
          required="true"
          :selectOptions="optionsIndicatived"
          v-model="form.phone_country_abrev"
        />

        <BaseInput
          ref="phone"
          type="text"
          phoneNumber
          maxlength="15"
          label="Número de teléfono"
          v-model="form.phone"
          placeholder="Fijo, Ej: 6011234567 o Celular, Ej: 3211234567"
          required="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseInput
          ref="email"
          type="text"
          label="Correo electrónico"
          v-model="form.email"
          placeholder="Ej: consumidor@consumidor.com"
          :maxlength="50"
          required="true"
        />

        <BaseSelect
          ref="direction"
          label="Dirección"
          :selectOptions="formOptions.optionsDirections"
          v-model="form.direction"
          required="true"
        />
      </div>

      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseSelect
          ref="cargo"
          label="Cargo"
          :selectOptions="formOptions.optionsPosition"
          v-model="form.position"
          required="true"
        />
        <BaseSelect
          ref="rol"
          label="Tipo de rol"
          :selectOptions="optionsGroupCompany"
          v-model="form.groups"
          required="true"
        />
      </div>

      <div v-if="feedback" class="mt-7">
        <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
      </div>

      <div
        class="w-full lg:max-w-sm mt-8 ml-auto flex flex-col lg:flex-row lg:justify-end gap-5"
      >
        <a-button
          type="danger"
          @click="$router.go(-1)"
          ghost
          shape="round"
          :block="true"
          :loading="loading"
        >
          <span class="mx-2">CANCELAR</span>
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          shape="round"
          :block="true"
          :loading="loading"
        >
          <span class="mx-2">GUARDAR</span>
        </a-button>
      </div>
    </form>
  </Wrapper>
</template>

<script>
import { checkPhone } from "../../../utils/twoFactorAuthenticate";
export default {
  data() {
    return {
      loading: false,
      feedback: null,
      form: {
        first_name: "",
        last_name: "",
        phone_country_abrev: "",
        phone: "",
        email: "",
        identification_type: "",
        identification_number: "",
        position: "",
        groups: "",
        person_type: 1,
        is_active: true,
        direction: "",
      },
    };
  },

  methods: {
    async createUser() {
      if (!this.checkForm()) {
        this.showToast("error", "Todos los campos son obligatorios.");
        return;
      }
      if (!this.validatePhone()) {
        return;
      }

      this.form.is_confirmed_phone = false; 
      
      const myForm = {
        ...this.form,
        groups: [+this.form.groups],
        position: +this.form.position,
        direction: +this.form.direction,
        identification_type: +this.form.identification_type,
        email: this.form.email.toLowerCase(),
      };
      this.loading = true;
      let { error, data } = await this.$api.createUser(myForm);
      this.loading = false;

      if (error) {
        this.showToast("error", "Error creando el usuario.");
        this.feedback = error.error;
      }

      if (data) {
        if (data.code === 401) {
          this.showToast("error", data.error);
          this.feedback = data.error;
        } else if (data.code === 402) {
          this.showToast("error", data.error);
          this.feedback = data.error;
        } else if (data.code === 403) {
          this.showToast("error", data.error);
          this.feedback = data.error;
        } else if (data.code === 404) {
          this.showToast("error", data.error);
          this.feedback = data.error;
        } else {
          this.showToast("success", "Usuario creado correctamente.");
          this.$router.go(-1);
        }
      }
    },
    checkForm() {
      this.feedback = null;
      let refs = { ...this.$refs };
      let valid = false;
      for (let ref in refs) {
        if (
          (refs[ref] && refs[ref].required && refs[ref].value === "") ||
          refs[ref]?.invalid == true
        ) {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }
      if (this.$validator.isEmpty(this.form.first_name)) {
        this.feedback = "Introduzca el nombre completo del usuario";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.last_name)) {
        this.feedback = "Introduzca los apellidos del usuario";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.identification_type + "")) {
        this.feedback = "Seleccione un tipo de identificación";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.identification_number)) {
        this.feedback = "Introduzca un número de identificación válido";
        return valid;
      }

      if (this.form.identification_number.length > 14) {
        this.feedback =
          "Máxima longitud del número de identificación es 14 caracteres.";
        return valid;
      }

      if (this.$validator.isEmpty(this.form.phone_country_abrev + "")) {
        this.feedback = "Seleccione un país del teléfono";
        return valid;
      }

      this.form.phone = this.form.phone.replace(/\s/g, "");

      if (this.form.phone && this.form.phone.toString().length < 7) {
        this.feedback = "Ingrese un número de teléfono válido.";
        return valid;
      }

      if (this.form.phone && this.form.phone.toString().length > 15) {
        this.feedback = "El número de teléfono no puede ser mayor a 15.";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.email)) {
        this.feedback = "Introduzca un correo electrónico";
        return valid;
      }
      this.form.email = this.form.email.replace(/\s/g, "");
      if (!this.$validator.isEmail(this.form.email)) {
        this.feedback = "Introduzca un correo válido";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.position + "")) {
        this.feedback = "Seleccione un cargo";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.groups + "")) {
        this.feedback = "Seleccione un rol";
        return valid;
      }

      if (this.$validator.isEmpty(this.form.direction + "")) {
        this.feedback = "Seleccione una dirección";
        return valid;
      }
      return true;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    validatePhone() {
      let valid = false;
      if (this.form.phone) {
        const { getNumber, isAllowed, typePhone, indicativePhone } = checkPhone(
          this.form.phone,
          this.form.phone_country_abrev
        );

        if (isAllowed) {
          this.feedback = "";
          this.form.phoneTransform = getNumber;
          this.form.phone_type_id = typePhone;
          this.form.phone_indicative = indicativePhone;
          valid = true;
        } else {
          this.feedback = "Introduzca un número de teléfono válido";
        }
      }
      return valid;
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    optionsGroupCompany: function () {
      return this.$store.state.form.optionsGroupCompany.filter(
        (option) => option?.detail_group?.state !== false
      );
    },
    optionsIndicatived: function () {
      return this.$store.state.form?.optionsCountriesAbrevs;
    },
  },
};
</script>
